.add-user-container {
  padding: 1em;
}

.message {
  text-align: center;
  margin: 20px 0;
  color: #00bf2c;
}

.error {
  text-align: center;
  margin: 20px 0;
  color: tomato;
}