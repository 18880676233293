.filter-container {
  padding: .5em;
}

.filter-icon {
  font-size: x-small;
  margin-left: .5em;
}

.active {
  color: brown;
}

.search-spacer {
  margin-top: .5em;
  margin-bottom: .5em;
}

.options-container {
  height: 200px;
  min-height: 160px;
  overflow-y: auto;
  width: 180px;
  overflow-x: hidden;
}

.option-label {
  margin-left: .5em;
}