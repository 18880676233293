.mg-20 {
  margin: 20px !important;
}

.download-cell {
  color: #800080;
}

.table-header thead {
  background-color: #e5e5e5 !important;
}

.phone-number {
  white-space: nowrap;
}

.reload-btn {
  float: right;
  margin: 20px;
  font-size: 20px;
}
