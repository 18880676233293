.download-button {
  margin: 10px;
}

.export-picker {
  margin: 0 auto;
}

.export-info {
  display: grid;
  gap: 0.2em;
  grid-template-columns: auto 1fr;
}