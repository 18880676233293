.mgt-80 {
  margin-top: 80px !important;
}

.logo {
  max-width: 30%;
}

.login-box {
  background-color: white !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 40px;
}

.title {
  font-weight: bold;
}
