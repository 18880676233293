.global-stats-indicator {
  padding: 20px;
  background-color: #fff !important;
  background-clip: content-box;
}
.container-charts {
  display: flex;
}
.pie-charts {
  text-align: center;
  margin-bottom: 15px;
  padding: 20px 0;
}
.control-points-scan-time {
  font-style: italic;
}
.padding-box {
  padding: 20px;
}
