.content {
  background-color: #ffffff;
}

.menu {
  margin-top: 70px;
}

.menu a {
  border: 2pt solid #800080;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 13pt;
  font-weight: bold;
  padding: 10px 30px 10px 30px;
  margin: 20px;
}

.menu a.active {
  background-color: #800080;
  color: #ffffff;
  background-clip: padding-box;
}

.menu a:hover {
  text-decoration: none;
}

.content {
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 0 !important;
}

.pointer {
  cursor: pointer;
}
